import React from "react";
import { Helmet } from "react-helmet";

const GoogleFormEmbed = () => {
  return (
    <div>
      <Helmet>
        <script type="text/javascript">{`
          !function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


  ttq.load('CTV75E3C77U5CBL1QHL0');
  ttq.page();
}(window, document, 'ttq');
        `}</script>
      </Helmet>
      <Helmet>
        <script type="text/javascript">{`
         (function (e, t, n) {
    if (e.snaptr) return;
    var a = e.snaptr = function () {
      a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
    };
    a.queue = [];
    var s = "script",
        r = t.createElement(s);
    r.async = true;
    r.src = n;
    var u = t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r, u);
  })(window, document, "https://sc-static.net/scevent.min.js");

  snaptr("init", "03f59391-2660-4865-ad6f-5e0fc0875652", {
    user_email: "__INSERT_USER_EMAIL__"
  });

  snaptr("track", "PAGE_VIEW");
        `}</script>
      </Helmet>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeiOJQvlbh6nWGippRYxKrUnZmzv7-bF41t1H4tMPmCS3XHQw/viewform?embedded=true"
          width="640"
          height="1339"
          title="Google Form"
          style={{ border: "none" }}
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default GoogleFormEmbed;

import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-media-solution">
      <div className="privacy-policy-wrapper container">
        <h1>Privacy Policy</h1>
        <p>
          At <strong>Blink Digital</strong>, we know you care about how your
          personal information is used and shared. That’s why we take your
          privacy seriously. Please read the following to learn more about Blink
          Digital’s Privacy Policy ("Privacy Policy").
        </p>

        <h2>Scope of the Privacy Policy</h2>
        <p>
          This Privacy Policy describes how the personally identifiable
          information ("Personal Information") you may provide on Blink
          Digital’s website ("Website" or "Service") and any of its related
          products and services (collectively, "Services") is collected,
          protected, and used. By accessing and using the Website and Services,
          you acknowledge that you have read, understood, and agree to be bound
          by the terms of this Agreement.
        </p>
        <p>
          This Policy does not apply to the practices of companies that we do
          not own or control, or to individuals that we do not employ or manage.
        </p>

        <h2>What Does This Privacy Policy Cover?</h2>
        <p>
          This Privacy Policy covers Blink Digital’s treatment of personally
          identifiable information ("Personal Data") that Blink Digital gathers
          when you are accessing or using our Services. We may process only
          minimal user data, only as much as it is absolutely necessary to
          maintain the Website and Services.
        </p>
        <p>
          Blink Digital uses this Personal Information internally in connection
          with Services, including to personalize, provide, and improve your use
          of Blink Digital.
        </p>

        <h2>Will Blink Digital Ever Change This Privacy Policy?</h2>
        <p>
          We are constantly striving to improve our Services, so we might need
          to change this Privacy Policy from time to time as well. But we will
          alert you of the changes via email or by placing a notice on our
          website, Blink Digital. If you use the Services after any changes to
          the Privacy Policy have been posted, that means you agree to all of
          the changes.
        </p>

        <h2>What Information Does Blink Digital Collect?</h2>
        <h3>Information You Provide to Us:</h3>
        <p>
          We receive and store any information you knowingly provide to us. For
          example, through the registration process and/or through your account
          settings, we may collect Personal Information such as your name, email
          address, phone number, and third-party account credentials (e.g.,
          Facebook account ID, page access & ads manager access).
        </p>
        <p>
          If you provide your third-party account credentials to us or otherwise
          sign in to the Services through a third-party site or service, you
          understand some content and/or information in those accounts ("Third
          Party Account Information") may be transmitted into your account with
          us if you authorize such transmissions.
        </p>

        <h3>Communications:</h3>
        <p>
          If you’ve provided us with the means to do so, we may communicate with
          you. For example, if you’ve given us your email address, we may send
          you promotional email offers on behalf of other businesses or email
          you about your use of the Services.
        </p>

        <h3>Voluntary Information:</h3>
        <p>
          When you voluntarily send us electronic mail, we will keep a record of
          this information so that we can respond to you. We only collect
          information from you when you register on our site or fill out a form.
        </p>
        <p>
          In case you have submitted your personal information and contact
          details, we reserve the right to call, SMS, email, or WhatsApp you
          about our products and offers, even if your number has DND activated
          on it.
        </p>

        <h2>Data Sharing</h2>
        <p>
          We do not share any of the user data with third-party tools, including
          AI models.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a href="mailto:privacy@blinkdigital.com">privacy@blinkdigital.com</a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
